// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
// import "./static/css/fonts.css"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
// import "prismjs/themes/prism.css"
